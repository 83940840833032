import React from 'react'
import * as goldJson from '../data/gold.json'

export const InputAmount = ({ value, change }) => <input
  type="number"
  name="name"
  onChange={change}
  value={value}
  placeholder="enter amount"
/>

export const YearSelect = ({ onSelect, value, startYear = 1928 }) => <select title="select year" onChange={onSelect} value={value}>
  {
    Object.keys(goldJson)
      .filter(e => startYear && e >= startYear)
      .map((year, index) => <option key={index}>{year}</option>)
  }
</select>

export const CurrencySelect = ({ value, change }) =>
  <select title="select currency" onChange={change} value={value}>
    <option>USD</option>
    <option>GBP</option>
  </select>

export const Loader = () => <div className="lds-ring">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
