import React from 'react'
import * as exchangeJson from '../../data/exchange.json'
import * as goldJson from '../../data/gold.json'
import { Chart } from './line-chart'

const getData = (curr, startYear, endYear, defaultCurrency) => {
  if (curr !== defaultCurrency) {
    return  Object
      .keys(exchangeJson[curr])
      .filter(x => Number.parseInt(x) >= startYear && x <= endYear)
      .sort((a, b) => a - b)
      .map(x => [x, 1/(goldJson[x] / exchangeJson[curr][x]) * 3110])
  }
  return Object.keys(goldJson)
    .filter(x => x >= startYear && x <= endYear)
    .sort((a, b) => a - b)
    .map(x => [x, 1/goldJson[x] * 3110])
}

export const GoldChart = ({ currency, startYear, endYear, defaultCurrency, height }) => {
  const data = getData(currency, startYear, endYear, defaultCurrency)
  const startGrams = data[0][1]
  const endGrams = data[data.length - 1][1]

  let direction = 'lost'
  let lossPercentage = 100-(100*endGrams/startGrams)

  if (lossPercentage < 0) {
    direction = 'gained'
    lossPercentage = lossPercentage * -1
  }

  const title = `Grams of gold purchased by 1${currency} ${startYear} to ${endYear}`
  const subtitle = `${currency} ${direction} ${lossPercentage.toFixed(2)}% of purchasing power in ${endYear - startYear} years`
  const newData = data.map(d => ({ year: d[0], grams: Number.parseFloat(d[1].toFixed(3)) }))

  return <div title="gold chart" style={{ height: `${height + 20}px`, position: 'relative' }}>
    <h4 style={{ fontSize: '0.9em', lineHeight: '1.1em' }}>
      {title}<br/>
      <span style={{ fontSize: '0.8em', fontWeight: 'normal' }}>{subtitle}</span>
    </h4>
    <Chart data={newData} dataKey="grams" xDataKey="year" lineLabel={`g/${currency}`} height={height} yLabel="Grams" />
  </div>
}
