import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import React from 'react'

export const Chart = ({ children, data, dataKey, xDataKey, lineLabel, height, yLabel }) => {
  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey}/>
        <YAxis scale="auto">
          <Label angle={-90} position="insideLeft">{yLabel}</Label>
        </YAxis>
        <Tooltip />
        <Legend />
        <Line
          dot={false}
          type="monotone"
          dataKey={dataKey}
          stroke="#8884d8"
          name={lineLabel}
          activeDot={{ r: 8 }}
        />
        {children}
      </LineChart>
    </ResponsiveContainer>
  )
}
