import * as goldJson from '../data/gold.json'
import * as exchangeJson from '../data/exchange.json'
import React from 'react'

export const GoldPriceTable = ({ curr, startYear, endYear, defaultCurrency }) => {
  const rows = Object.keys(goldJson)
    .filter(x => Number.parseInt(x) === startYear || Number.parseInt(x) === endYear)
    .sort()
    .map((year) => !isNaN(goldJson[year]) && ({
      year: year,
      value: (goldJson[year]/100).toFixed(2).toString(),
      exchange: curr === defaultCurrency ? 'n/a' : (exchangeJson[curr][year]),
      valueInCurrency: curr === defaultCurrency ? 'n/a' : (goldJson[year]/100/exchangeJson[curr][year]).toFixed(2)
    }))

  return <table>
    <thead>
    <tr>
      <th>Year</th><th>Gold(USD)</th>
      {curr !== 'USD' && <><th>USD to {curr}</th><th>Gold({curr})</th></>}
    </tr>
    </thead>
    <tbody>
    {
      rows.map((r, index) => <tr key={index}>
        <td>{r.year}</td><td>{r.value}</td>
        {curr !== 'USD' && <><td>{r.exchange}</td><td>{r.valueInCurrency}</td></>}
      </tr>)
    }
    </tbody>
  </table>
}
