import React, { useEffect, useState } from 'react'
import * as exchangeJson from '../data/exchange.json'
import * as goldJson from '../data/gold.json'
import { GoldPriceTable } from './gold-price-table'
import { CurrencySelect, InputAmount, YearSelect } from './form-controls'
import { GoldChart } from './charts/gold-chart'
import { HouseChart, SalaryChart } from './charts/other-charts'

const defaultStartYear = 1928
const defaultEndYear = 2024
const defaultCurrency = 'USD'

export const GoldContent = ({ useCurrent = false }) => {
  const [amount, setAmount] = useState(100)
  const [startYear, setStartYear] = useState(defaultStartYear)
  const [endYear, setEndYear] = useState(defaultEndYear)
  const [currency, setCurrency] = useState(defaultCurrency)
  const [goldPrice, setGoldPrice] = useState('')
  const [latestGold, setLatestGold] = useState(0)

  useEffect(() => {
    if (useCurrent) {
      fetch(`${process.env.REACT_APP_API_URL}XAU/${currency}`)
        .then(result => result.json())
        .then(({ price }) => {
          setGoldPrice(price.toFixed(2))
        })
        .catch(err => {
          console.error(err)
          setGoldPrice('not available')
        })
    }
  }, [currency, useCurrent])

  useEffect(() => {
    if (!latestGold) {
      fetch(`${process.env.REACT_APP_API_URL}XAU`)
        .then(result => result.json())
        .then((price) => {
          setLatestGold(Math.round(price * 100))
        })
        .catch(err => {
          console.error(err)
          setLatestGold('not available')
        })
    }
  })

  const getExchangedValue = (currency, usd, year) =>
    currency === defaultCurrency
      ? usd
      : usd / exchangeJson[currency][year]

  const calculate = (latest) =>
    (amount / getExchangedValue(currency, goldJson[startYear], startYear) * (latest ? Number.parseFloat(goldPrice) * 100 : getExchangedValue(currency, goldJson[endYear], endYear))).toFixed(2)

  const Result = () => <span>{calculate()}</span>

  // Object.keys(goldJson).sort().forEach((k) => {
  //   const span = 5
  //   const keyAsInt = Number.parseInt(k)
  //   if (keyAsInt + span < 2025) {
  //     console.log(`${keyAsInt} to ${keyAsInt + span}`, ((goldJson[keyAsInt + span] - goldJson[keyAsInt])/goldJson[keyAsInt]) * 100)
  //   }
  // })

  return <div style={{ width: '95%', margin: 'auto', fontSize: '1.5' }}>
    <div>The current gold price in {currency} is {goldPrice}</div>
    <p>
      <InputAmount value={amount} change={(e) => setAmount(e.target.value)}/> <CurrencySelect value={currency} change={(e) => setCurrency(e.target.value)}/> in <YearSelect onSelect={(e) => {setStartYear(Number.parseInt(e.target.value))}} value={startYear}/>
    </p>
    <p>
      would have bought the same as <Result/> in <YearSelect onSelect={(e) => {setEndYear(Number.parseInt(e.target.value))}} defaultValue={defaultEndYear} value={endYear} startYear={startYear}/>
    </p>
    <p>
      {goldPrice && goldPrice !== 'not available' ? `or now ${calculate(true)} ${currency}` : ''}
    </p>
    <GoldChart startYear={startYear} endYear={endYear} currency={currency} defaultCurrency={defaultCurrency} height={400}/>
    <h4 style={{ marginTop: '4em' }}>UK house prices in gold</h4>
    <HouseChart startYear={startYear} endYear={endYear} latest={latestGold}/>
    <h4 style={{ marginTop: '4em' }}>UK salaries in gold</h4>
    <SalaryChart startYear={startYear} endYear={endYear} latest={latestGold}/>
    <GoldPriceTable curr={currency} startYear={startYear} endYear={endYear} defaultCurrency={defaultCurrency}/>
    <p className="disclaimer">
      Note: calculations are for illustrative purposes only based on average monthly closing prices for each year.<br/>
      The current year aggregate price for this year may not be up to date. <strong>&copy; ifocs.com 2024</strong>
    </p>
  </div>
}
